import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Label} from "reactstrap";
import {gql, useQuery} from "@apollo/client";
import _find from 'lodash/find'
import {fromJS} from 'immutable'

const categorySearchQuery = gql`
    query($query: String!){
        categoryStubs(query: $query) {
            item {
                id
                name
            }
        }
    }
`

const subCategorySearch = gql`
    query($categoryId: Int) {
        category(id: $categoryId) {
            parentCategory {
                name
            }
            name
            childCategories(take: 1000) {
                edges {
                    id
                    name
                }
            }
        }
    }
`

const AddCategoryModal = ({showModal, closeModal, addCategory}) => {

    const [query, setQuery] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [location, setLocation] = useState('0')
    const {loading, error, data} = useQuery(categorySearchQuery, {
        variables: {
            query,
        }
    })

    const {data: subData} = useQuery(subCategorySearch, {
        variables: {
            categoryId: selectedCategory ? parseInt(selectedCategory.id, 10) : null,
        }
    })

    const selectCategory = (category) => {
        setSelectedCategory(category)
        setQuery('')
    }

    const addSelectedCategory = () => {
        const locationCat = _find(subData.category.childCategories.edges, ['id', location])
        addCategory(fromJS({
            id: parseInt(location, 10),
            name: `${subData.category.parentCategory.name} > ${subData.category.name} > ${locationCat.name}`
        }))
        setQuery('')
        setSelectedCategory(null)
        setLocation('0')
        closeModal()
    }

    return (
        <Modal isOpen={showModal} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>Add Category</ModalHeader>
            <ModalBody>
                <Input value={query} onChange={e => {setQuery(e.target.value); setSelectedCategory(null)}} placeholder={'Search...'}/>
                <div style={{
                    position: 'absolute',
                    border: '1px solid #d4d4d4',
                    borderTop: 'none',
                    zIndex: 99,
                    top: '100%',
                    left: '0',
                    right: '0',
                    backgroundColor: '#fff',
                    display: selectedCategory === null && query !== '' ? 'block' : 'none'
                }}>
                    {data && data.categoryStubs && data.categoryStubs.map((result, index) => (
                        <div
                            key={result.item.id}
                            style={{
                                padding: '3px 10px',
                            }}
                            onClick={() => {selectCategory(result.item)}}
                        >{result.item.name}</div>
                    ))}

                </div>
                {selectedCategory && <div>
                    <Label>Category:</Label>
                    <div>{selectedCategory.name}</div>
                    <Label>Location</Label>
                    <Input type="select" value={location} onChange={e => setLocation(e.target.value)}>
                        <option value={0}>Select Location...</option>
                        {subData && subData.category.childCategories.edges.map((childCat) => {
                            return (
                                <option key={childCat.id} value={childCat.id}>{childCat.name}</option>
                            )
                        })}
                    </Input>
                </div>}
            </ModalBody>
            <ModalFooter>
                <Button disabled={location === '0'} color="primary" onClick={addSelectedCategory}>Add</Button>{' '}
                <Button color="secondary" onClick={closeModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddCategoryModal;