/*!

=========================================================
* Now UI Dashboard PRO React - v1.4.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import {  } from "@reach/router";
// javascript plugin used to create scrollbars on windows
// react plugin for creating notifications
import NotificationAlert from "react-notification-alert";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import PanelHeader from "components/PanelHeader/PanelHeader";
import { Row, Col, Card, CardHeader, CardBody, CardTitle } from "reactstrap";

class Admin extends React.Component {
  state = {
    sidebarMini: false,
    backgroundColor: "blue",
  };
  mainPanel = React.createRef();
  notificationAlert = React.createRef();

  minimizeSidebar = () => {
    //var message = "Sidebar mini ";
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
      //message += "deactivated...";
    } else {
      this.setState({ sidebarMini: true });
      //message += "activated...";
    }
    document.body.classList.toggle("sidebar-mini");
    // var options = {};
    // options = {
    //   place: "tr",
    //   message: message,
    //   type: "info",
    //   icon: "now-ui-icons ui-1_bell-53",
    //   autoDismiss: 7,
    // };
    //this.notificationAlert.current.notificationAlert(options);
  };
  handleColorClick = (color) => {
    this.setState({ backgroundColor: color });
  };
  
  getActiveRoute = (routes) => {
    let activeRoute = "Start Local Admin System";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = this.getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.pathname.indexOf(
            routes[i].layout + routes[i].path
          ) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  render() {

    console.log()
    return (
      <div className="wrapper" style={{backgroundColor: 'grey', overflow: 'auto'}}>
        <NotificationAlert ref={this.notificationAlert} />
        <Sidebar
          {...this.props}
          routes={[]}
          minimizeSidebar={this.minimizeSidebar}
          backgroundColor={this.state.backgroundColor}
        />
        <div className="main-panel" ref={this.mainPanel} style={{height: 'auto'}}>
          <AdminNavbar
            {...this.props}
            brandText={this.getActiveRoute([])}
          />
          {this.props.children.props.location.pathname !== "/" ? this.props.children : (
            <>
              <PanelHeader size="sm"/>
              <div className="content">
                <Row>
                  <Col md={12}>
                    <Card>
                      <CardHeader>
                        <CardTitle tag="h4" className={"pull-left"}>
                          Start Local Admin System
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
    
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          )}          
        </div>
      </div>
    );
  }
}

export default Admin;
