import React from 'react'
import { gql, useQuery } from '@apollo/client'
import EditBusinessPage from 'views/EditBusinessPage'

const GET_BUSINESS_QUERY = gql`
    query($id: Int!) {
        business(id: $id) {
            id
            abn
            name
            yearEstablished
            url
            numberOfEmployees

            hoursMonOpen
            hoursMonClose
            hoursTueOpen
            hoursTueClose
            hoursWedOpen
            hoursWedClose
            hoursThuOpen
            hoursThuClose
            hoursFriOpen
            hoursFriClose
            hoursSatOpen
            hoursSatClose
            hoursSunOpen
            hoursSunClose
            hoursHolidayOpen
            hoursHolidayClose

            presenceOnlineOnly
            byAppointmentOnly
            address
            suburb
            state
            postcode

            contactName
            email
            mobile
            phone
            fax
            freecall

            isNew
            isPopular
            isValidated
            votes
            rating

            about
            description
            descriptionShort
            specialties
            mentionUsText

            categories {
                edges {
                    id
                    name
                    buildDirectoryName
                    parentCategory {
                        id
                        name
                        buildDirectoryName
                        parentCategory {
                            id
                            name
                            buildDirectoryName
                        }
                    }
                }
            }
        }
    }
`

const EditExistingBusinessPage = ({id}) => {
    const {loading, error, data} = useQuery(GET_BUSINESS_QUERY, {
        variables: {
            id: parseInt(id, 10),
        }
    })

    if(!data || loading) {
        return (
            <div>Loading...</div>
        )
    }

    if(error) {
        return (
            <div>Error</div>
        )
    }

    return <EditBusinessPage business={data.business} isNew={false} />
}

export default EditExistingBusinessPage