import React from 'react';
import './App.css';
import LoginPage from './views/auth/LoginPage';
import store from 'store2'
import { AUTH_TOKEN } from 'storage-keys';
import { Router, useNavigate, useLocation } from "@reach/router"
import Admin from 'layouts/Admin';
import BusinessesPage from 'views/BusinessesPage'
import EditBusinessPage from 'views/EditBusinessPage';
import EditExistingBusinessPage from 'views/EditExistingBusinessPage'

function App() {

  const isAuthenticated = store.session(AUTH_TOKEN)
  const location = useLocation().pathname
  const navigate = useNavigate()
  
if(!isAuthenticated && location !== '/login') {
    console.log('redirecting to login page for auth')
    setTimeout(() => {
      navigate("/login", {replace: true})
    }, 1)
    return null
}

if(isAuthenticated && location === '/login') {
  console.log('redirecting to main page')
  setTimeout(() => {
    navigate("/", {replace: true})
  }, 1)
  return null
}

  return (
    <Router>
      <Main path="/">
        <BusinessesPage path="/businesses"/>
        <EditBusinessPage path="/businesses/new"/>
        <EditExistingBusinessPage path="/businesses/:id"/>
      </Main>
      <LoginPage path="/login"/>
    </Router>
  );
}

const Main = ({children}) => {
  return (
    <Admin>
      {children}
    </Admin>
  )
}

export default App;
