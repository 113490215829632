import React, {useState} from 'react'
import {fromJS, Map} from 'immutable'
import {useMutation, gql} from '@apollo/client'

import { Row, Col, Card, CardHeader, CardBody, CardTitle, Button,
    Input,
    FormGroup,
    Table,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form} from "reactstrap";
    import Switch from "react-bootstrap-switch";
    import PanelHeader from "components/PanelHeader/PanelHeader";
import AddCategoryModal from "./AddCategoryModal";

const CREATE_UPDATE_MUTATION = gql`
    mutation($id: Int, $business: BusinessInput!, $categoryIds: [Int!]!) {
        createOrUpdateBusiness(id: $id, business: $business, categoryIds: $categoryIds) {
            id
            abn
            name
            yearEstablished
            url
            numberOfEmployees

            hoursMonOpen
            hoursMonClose
            hoursTueOpen
            hoursTueClose
            hoursWedOpen
            hoursWedClose
            hoursThuOpen
            hoursThuClose
            hoursFriOpen
            hoursFriClose
            hoursSatOpen
            hoursSatClose
            hoursSunOpen
            hoursSunClose
            hoursHolidayOpen
            hoursHolidayClose

            presenceOnlineOnly
            byAppointmentOnly
            address
            suburb
            state
            postcode

            contactName
            email
            mobile
            phone
            fax
            freecall

            isNew
            isPopular
            isValidated
            votes
            rating
            
            about
            description
            descriptionShort
            specialties
            mentionUsText
        }
    }
`

const EditBusinessPage = ({business: b = {
    isNew: true,
    isPopular: false,
    isValidated: false,
    presenceOnlineOnly: false,
    byAppointmentOnly: false,
}}) => {

    const [categories, setCategories] = useState(fromJS(b.categories ? b.categories.edges.map((category) => {
        return {
            id: category.id,
            name: `${category.parentCategory.parentCategory.name} > ${category.parentCategory.name} > ${category.name}`
        }
    }) : fromJS([])))
    const [categoriesChanged, setCategoriesChanged] = useState(false)
    const [savedBusiness, setSavedBusiness] = useState(fromJS(b).map(value => value === null ? '' : value))
    const [business, setBusiness] = useState(b.id ? Map() : fromJS(b))
    const [createUpdateBusinessMutation, {loading}] = useMutation(CREATE_UPDATE_MUTATION)

    function getValue(prop, emptyValue = '') {
        return business.get(prop, savedBusiness.get(prop, emptyValue))
    }

    const isNew = savedBusiness.get("id") === undefined
    const changed = categoriesChanged || !business.isEmpty()

    const setNewCategories = (newCategories) => {
        setCategories(newCategories)
        if(!categoriesChanged) {
            setCategoriesChanged(true)
        }
    }

    function createUpdateBusiness() {

        let updateBusiness = business

        // Nullify empty values and trim strings
        updateBusiness = updateBusiness.map((value) => {
            if(typeof value === 'string') {
                value = value.trim();
            }
            if(value === "") {
                return null;
            }
            return value
        })

        // Cast data
        if(updateBusiness.has('votes')) {
            updateBusiness = updateBusiness.set('votes', parseInt(updateBusiness.get('votes'), 10))
        }
        if(updateBusiness.has('rating')) {
            updateBusiness = updateBusiness.set('rating', parseFloat(updateBusiness.get('rating')))
        }

        createUpdateBusinessMutation({
            variables: {
                id: isNew ? null : parseInt(savedBusiness.get('id'), 10),
                business: updateBusiness.toJS(),
                categoryIds: categories.map((cat) => {
                    return parseInt(cat.get('id'), 10)
                })
            }
        }).then(({data}) => {
            if(data && data.createOrUpdateBusiness) {
                setSavedBusiness(fromJS(data.createOrUpdateBusiness))
                setBusiness(Map())
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    return (
        <>
        <PanelHeader size="sm"/>
          <div className="content">
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4" className={"pull-left"}>
                      {isNew ? "New" : "Edit"} Business
                    </CardTitle>
                      <Button disabled={loading || !changed} color="primary" className={"pull-right"} onClick={() => createUpdateBusiness()}>{isNew ? (loading ? "Creating" : "Create") : (loading ? "Saving" : (changed ? "Save" : "Saved"))}</Button>
                      <div style={{clear: "both"}}/>
                  </CardHeader>
                  <CardBody>
                      <BusinessForm getValue={getValue} setBusiness={setBusiness} business={business} loading={loading}/>
                  </CardBody>
                </Card>
                  <Card>
                      <CardHeader>
                          <CardTitle tag="h4" className={"pull-left"}>
                              Categories
                          </CardTitle>
                          <div style={{clear: "both"}}/>
                      </CardHeader>
                      <CardBody>
                        <CategoriesForm categories={categories} setCategories={setNewCategories}/>
                      </CardBody>
                  </Card>
            </Col>
            </Row>
            </div>
        </>
    )
}

export default EditBusinessPage

const CategoriesForm = ({categories, setCategories}) => {

    const [removeCat, setRemoveCat] = useState(null);
    const [showAddModal, setAddModal] = useState(false);

    const removeCategory = (removeCategory) => {
        setCategories(categories.filter((testCategory) => {
            return testCategory.get('id') !== removeCategory.get('id')
        }))
        setRemoveCat(null)
    }

    return (
        <>
            <Table responsive hover>
                <thead className="text-primary">
                    <tr>
                        <th>Name</th>
                        <th style={{width: 50}}/>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((category) => {
                        return (
                            <tr key={category.get('id')}>
                                <td>{category.get('name')}</td>
                                <td onClick={() => setRemoveCat(category)}>Remove</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan={2}>
                            <div onClick={() => setAddModal(true)}>Add Category</div>
                        </td>
                    </tr>
                </tbody>
            </Table>
            <Modal isOpen={removeCat !== null} toggle={() => setRemoveCat(null)}>
                <ModalHeader toggle={() => setRemoveCat(null)}>Confirm Removal</ModalHeader>
                <ModalBody>
                    <div>Are you sure you want to remove the category:</div>
                    <div>{removeCat && removeCat.get('name')}</div>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => removeCategory(removeCat)}>Remove</Button>{' '}
                    <Button color="secondary" onClick={() => setRemoveCat(null)}>Cancel</Button>
                </ModalFooter>
            </Modal>
            <AddCategoryModal showModal={showAddModal} closeModal={() => setAddModal(false)} addCategory={(addCategory) => setCategories(categories.push(addCategory))}/>
        </>
    )
}

const BusinessForm = ({getValue, business, setBusiness, loading}) => {
    return (
        <Form>
            <CardTitle tag="h5">Business Details</CardTitle>
            <Row>
                <Col md="3">
                    <label>ABN</label>
                    <FormGroup>
                        <Input
                            value={getValue('abn')}
                            onChange={(e) => setBusiness(business.set('abn', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <label>Business Name</label>
                    <FormGroup>
                        <Input
                            value={getValue('name')}
                            onChange={(e) => setBusiness(business.set('name', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="3">
                    <label>Year Established</label>
                    <FormGroup>
                        <Input
                            value={getValue('yearEstablished')}
                            onChange={(e) => setBusiness(business.set('yearEstablished', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="9">
                    <label>URL</label>
                    <FormGroup>
                        <Input
                            value={getValue('url')}
                            onChange={(e) => setBusiness(business.set('url', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="3">
                    <label>No. of Employees</label>
                    <FormGroup>
                        <Input
                            value={getValue('numberOfEmployees')}
                            onChange={(e) => setBusiness(business.set('numberOfEmployees', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <label>Description</label>
                    <Input
                        value={getValue('description')}
                        onChange={(e) => setBusiness(business.set('description', e.target.value))}
                        type="textarea"
                        disabled={loading}
                    />
                </Col>
                <Col md="4">
                    <label>Description Short</label>
                    <Input
                        value={getValue('descriptionShort')}
                        onChange={(e) => setBusiness(business.set('descriptionShort', e.target.value))}
                        type="textarea"
                        disabled={loading}
                    />
                </Col>
                <Col md="4">
                    <label>About</label>
                    <Input
                        value={getValue('about')}
                        onChange={(e) => setBusiness(business.set('about', e.target.value))}
                        type="textarea"
                        disabled={loading}
                    />
                </Col>
                <Col md="4">
                    <label>Specialties</label>
                    <Input
                        value={getValue('specialties')}
                        onChange={(e) => setBusiness(business.set('specialties', e.target.value))}
                        type="textarea"
                        disabled={loading}
                    />
                </Col>
                <Col md="4">
                    <label>Mention Us</label>
                    <Input
                        value={getValue('mentionUsText')}
                        onChange={(e) => setBusiness(business.set('mentionUsText', e.target.value))}
                        type="textarea"
                        disabled={loading}
                    />
                </Col>
            </Row>

            <CardTitle tag="h5">Business Hours</CardTitle>
            <Row>
                <Col md="2">
                    <label>Monday Open</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursMonOpen')}
                            onChange={(e) => setBusiness(business.set('hoursMonOpen', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Monday Close</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursMonClose')}
                            onChange={(e) => setBusiness(business.set('hoursMonClose', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Tuesday Open</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursTueOpen')}
                            onChange={(e) => setBusiness(business.set('hoursTueOpen', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Tuesday Close</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursTueClose')}
                            onChange={(e) => setBusiness(business.set('hoursTueClose', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Wednesday Open</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursWedOpen')}
                            onChange={(e) => setBusiness(business.set('hoursWedOpen', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Wednesday Close</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursWedClose')}
                            onChange={(e) => setBusiness(business.set('hoursWedClose', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Thursday Open</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursThuOpen')}
                            onChange={(e) => setBusiness(business.set('hoursThuOpen', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Thursday Close</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursThuClose')}
                            onChange={(e) => setBusiness(business.set('hoursThuClose', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Friday Open</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursFriOpen')}
                            onChange={(e) => setBusiness(business.set('hoursFriOpen', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Friday Close</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursFriClose')}
                            onChange={(e) => setBusiness(business.set('hoursFriClose', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Saturday Open</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursSatOpen')}
                            onChange={(e) => setBusiness(business.set('hoursSatOpen', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Saturday Close</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursSatClose')}
                            onChange={(e) => setBusiness(business.set('hoursSatClose', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Sunday Open</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursSunOpen')}
                            onChange={(e) => setBusiness(business.set('hoursSunOpen', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Sunday Close</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursSunClose')}
                            onChange={(e) => setBusiness(business.set('hoursSunClose', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Public Holiday Open</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursHolidayOpen')}
                            onChange={(e) => setBusiness(business.set('hoursHolidayOpen', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Public Holiday Close</label>
                    <FormGroup>
                        <Input
                            value={getValue('hoursHolidayClose')}
                            onChange={(e) => setBusiness(business.set('hoursHolidayClose', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <CardTitle tag="h5">Location</CardTitle>
            <Row>
                <Col md="2">
                    <p className="category">Online only</p>
                    <Switch
                        onText={<i className="now-ui-icons ui-1_check" />}
                        offText={<i className="now-ui-icons ui-1_simple-remove" />}
                        value={getValue('presenceOnlineOnly')}
                        onChange={(el, state) => setBusiness(business.set('presenceOnlineOnly', state))}
                        onColor={'success'}
                        offColor={'danger'}
                        disabled={loading}
                    />
                </Col>
                <Col md="2">
                    <p className="category">By Appointment Only</p>
                    <Switch
                        onText={<i className="now-ui-icons ui-1_check" />}
                        offText={<i className="now-ui-icons ui-1_simple-remove" />}
                        value={getValue('byAppointmentOnly')}
                        onChange={(el, state) => setBusiness(business.set('byAppointmentOnly', state))}
                        onColor={'success'}
                        offColor={'danger'}
                        disabled={loading}
                    />
                </Col>
                <Col md="8"/>
                <Col md="4">
                    <label>Address</label>
                    <FormGroup>
                        <Input
                            value={getValue('address')}
                            onChange={(e) => setBusiness(business.set('address', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="4">
                    <label>Suburb</label>
                    <FormGroup>
                        <Input
                            value={getValue('suburb')}
                            onChange={(e) => setBusiness(business.set('suburb', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>State</label>
                    <FormGroup>
                        <Input
                            value={getValue('state')}
                            onChange={(e) => setBusiness(business.set('state', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Postcode</label>
                    <FormGroup>
                        <Input
                            value={getValue('postcode')}
                            onChange={(e) => setBusiness(business.set('postcode', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
            </Row>

            <CardTitle tag="h5">Contact Details</CardTitle>
            <Row>
                <Col md="6">
                    <label>Contact Name</label>
                    <FormGroup>
                        <Input
                            value={getValue('contactName')}
                            onChange={(e) => setBusiness(business.set('contactName', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="6">
                    <label>Email</label>
                    <FormGroup>
                        <Input
                            value={getValue('email')}
                            onChange={(e) => setBusiness(business.set('email', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="3">
                    <label>Mobile</label>
                    <FormGroup>
                        <Input
                            value={getValue('mobile')}
                            onChange={(e) => setBusiness(business.set('mobile', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="3">
                    <label>Landline</label>
                    <FormGroup>
                        <Input
                            value={getValue('phone')}
                            onChange={(e) => setBusiness(business.set('phone', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="3">
                    <label>Fax</label>
                    <FormGroup>
                        <Input
                            value={getValue('fax')}
                            onChange={(e) => setBusiness(business.set('fax', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="3">
                    <label>Freecall</label>
                    <FormGroup>
                        <Input
                            value={getValue('freecall')}
                            onChange={(e) => setBusiness(business.set('freecall', e.target.value))}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
            </Row>
            
            <CardTitle tag="h5">Directory</CardTitle>
            <Row>
                <Col md="2">
                    <p className="category">New</p>
                    <Switch
                        onText={<i className="now-ui-icons ui-1_check" />}
                        offText={<i className="now-ui-icons ui-1_simple-remove" />}
                        value={getValue('isNew')}
                        onChange={(el, state) => setBusiness(business.set('isNew', state))}
                        onColor={'success'}
                        offColor={'danger'}
                        disabled={loading}
                    />
                </Col>
                <Col md="2">
                    <p className="category">Popular</p>
                    <Switch
                        onText={<i className="now-ui-icons ui-1_check" />}
                        offText={<i className="now-ui-icons ui-1_simple-remove" />}
                        value={getValue('isPopular')}
                        onChange={(el, state) => setBusiness(business.set('isPopular', state))}
                        onColor={'success'}
                        offColor={'danger'}
                        disabled={loading}
                    />
                </Col>
                <Col md="2">
                    <p className="category">Validated</p>
                    <Switch
                        onText={<i className="now-ui-icons ui-1_check" />}
                        offText={<i className="now-ui-icons ui-1_simple-remove" />}
                        value={getValue('isValidated')}
                        onChange={(el, state) => setBusiness(business.set('isValidated', state))}
                        onColor={'success'}
                        offColor={'danger'}
                        disabled={loading}
                    />
                </Col>
                <Col md="2">
                    <label>Votes</label>
                    <FormGroup>
                        <Input
                            value={getValue('votes')}
                            onChange={(e) => setBusiness(business.set('votes', e.target.value))}
                            type={'number'}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
                <Col md="2">
                    <label>Rating</label>
                    <FormGroup>
                        <Input
                            value={getValue('rating')}
                            onChange={(e) => setBusiness(business.set('rating', e.target.value))}
                            type={'number'}
                            disabled={loading}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    )
}