import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ApolloProvider } from '@apollo/client';
import client from 'lib/ApolloClient'
import { Router } from "@reach/router"

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v=1.4.0";
import "assets/css/demo.css";

ReactDOM.render(
    <ApolloProvider client={client}>
      <Router>
        <App path="*"/>
      </Router>
    </ApolloProvider>,
  document.getElementById('root')
);
