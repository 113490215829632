import React, { useState, useEffect } from "react";
import store from 'store2'

// reactstrap components
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Form,
  Container,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
} from "reactstrap";
import { useNavigate} from "@reach/router"
import { useLazyQuery, gql } from '@apollo/client';

// core components
import logo from "assets/img/logo-white.png";

import bgImage from "assets/img/bglogin.jpg";
import { AUTH_TOKEN } from "storage-keys";

const AUTHENTICATE_QUERY = gql`
    query($email: String! $password: String!) {
        authenticate(email: $email password: $password) {
            success
            token
        }
    }
`

const LoginPage = () => {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailFocus, setEmailFocus] = useState(false)
    const [passwordFocus, setPasswordFocus] = useState(false)
    const [authenticate, { loading, data }] = useLazyQuery(AUTHENTICATE_QUERY);

    function login() {
        if(loading || email === '' || password === '') {
            // Console
        } else {
            authenticate({
                variables: {
                    email,
                    password,
                }
            })
        }
    }

    useEffect(() => {
        if(data && data.authenticate) {
            console.log(data.authenticate)
            if(data.authenticate.success) {
                store.session(AUTH_TOKEN, data.authenticate.token)
                navigate("/", {replace: true})
            }
        }
    }, [data, navigate])

    return (
        <div className="wrapper wrapper-full-page">
          <div className="full-page section-image">
          <div className="content">
            <div className="login-page">
              <Container>
                <Col xs={12} md={8} lg={4} className="ml-auto mr-auto">
                  <Form>
                    <Card className="card-login card-plain">
                      <CardHeader>
                        <div className="logo-container" style={{width: 206}}>
                          <img src={logo} alt="now-logo" />
                        </div>
                      </CardHeader>
                      <CardBody>
                        <InputGroup
                          className={
                            "no-border form-control-lg " +
                            (emailFocus ? "input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Email"
                            value={email}
                            disabled={loading}
                            onChange={(e) => setEmail(e.target.value)}
                            onFocus={(e) =>
                              setEmailFocus(true)
                            }
                            onBlur={(e) =>
                              setEmailFocus(false)
                            }
                          />
                        </InputGroup>
                        <InputGroup
                          className={
                            "no-border form-control-lg " +
                            (passwordFocus ? "input-group-focus" : "")
                          }
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons objects_key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="password"
                            placeholder="Password"
                            value={password}
                            disabled={loading}
                            onChange={(e) => setPassword(e.target.value)}
                            onFocus={(e) =>
                              setPasswordFocus(true)
                            }
                            onBlur={(e) =>
                              setPasswordFocus(false)
                            }
                          />
                        </InputGroup>
                      </CardBody>
                      <CardFooter>
                        <Button
                          block
                          color="primary"
                          size="lg"
                          className="mb-3 btn-round"
                          onClick={login}
                          disabled={loading}
                        >
                          {loading ? "Loading" : "Login"}
                        </Button>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Container>
            </div>
          </div>
          <div
            className="full-page-background"
            style={{ backgroundImage: "url(" + bgImage + ")" }}
          />
          </div>
        </div>
    )
}

export default LoginPage