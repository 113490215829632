import { ApolloClient, HttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
import store from 'store2'
import { AUTH_TOKEN } from 'storage-keys';
import {API_HOST} from 'config'

const AuthLink = new ApolloLink((operation, forward) => {
    let headers = {}
    const token = store.session(AUTH_TOKEN)

    if (token) {
        headers.authorization = `Bearer ${token}`
    }
    operation.setContext({
        headers
    })
    return forward(operation)
})

const link = ApolloLink.from([
    AuthLink,
    new HttpLink({
        uri: API_HOST,
    })
]);

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
});

export default client