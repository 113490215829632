import React from 'react'

import { Row, Col, Card, CardHeader, CardBody, CardTitle, Button, Table, InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input } from "reactstrap";
import PanelHeader from "components/PanelHeader/PanelHeader";
import { useQuery, gql } from '@apollo/client';
import { useState } from 'react';
import { useNavigate } from "@reach/router"
import useDebourne from 'lib/useDebounce'

const SEARCH_QUERY = gql`
    query($query: String!) {
        adminBusinessSearch(query: $query) {
            id
            name
            location
        }
    }
`

const BusinessesPage = () => {

    const [query, setQuery] = useState('')
    const navigate = useNavigate();

    const debouncedQuery = useDebourne(query, 500)

    const { loading, data } = useQuery(SEARCH_QUERY, {
        variables: {
            query: debouncedQuery.trim(),
        }
    })

    return (
        <>
        <PanelHeader size="sm"
          
          />
          <div className="content">
            <Row>
              <Col md={12}>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4" className={"pull-left"}>
                      Businesses
                      
                    </CardTitle>
                    <Button color="primary" className={"pull-right"} onClick={() => navigate('/businesses/new')}>New Business</Button>
                  </CardHeader>
                  <CardBody>
                  <form onSubmit={(e) => e.preventDefault()}>
              <InputGroup className="no-border">
                <Input 
                    value={query}
                    onChange={e => setQuery(e.target.value)}
                    placeholder="Search business name or business id" />
                <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <i className="now-ui-icons ui-1_zoom-bold" />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </form>
                  <Table responsive hover>
                    <thead className="text-primary">
                      <tr>
                        <th width="10%">ID</th>
                        <th width="45%">Name</th>
                        <th width="45%">Location</th>
                      </tr>
                    </thead>
                    <tbody>
                        {query.trim() !== "" && data && data.adminBusinessSearch &&
                      data.adminBusinessSearch.map((prop, key) => {
                        return (
                          <tr key={key} onClick={() => navigate(`/businesses/${prop.id}`)}>
                            <td>{prop.id}</td>
                            <td>{prop.name}</td>
                            <td>{prop.location}</td>
                          </tr>
                        );
                      })}
                      {loading && (
                            <tr>
                                <td colSpan={3}>Loading...</td>
                            </tr>
                        )}
                        {!loading && query !== '' && data && data.adminBusinessSearch && data.adminBusinessSearch.length === 0 && (
                            <tr>
                                <td colSpan={3}>No results found</td>
                            </tr>
                        )}
                        {!loading && query === '' && (
                            <tr>
                                <td colSpan={3}>Enter a search...</td>
                            </tr>
                        )}
                    </tbody>
                  </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          </>
    )
}

export default BusinessesPage